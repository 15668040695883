<template>
	<div class="container">

		<div class="row header">
			<div class="col">
				<h3>New Quiz</h3>
			</div>
		</div>

		<div class="row new-course">
			<div class="col">
				<v-form ref="form" class="brand-form">
					<InputTextField
						:id="'new_quiz_name'"
						v-model="newQuizName"
						:rules="[rules.required]"
					>
						<template #label>Quiz Name</template>
					</InputTextField>
					<InputTextArea
						v-model="newQuizDescription"
						:id="'new_quiz_description'"
					>
						<template #label> Description (Optional)</template>
					</InputTextArea>
				</v-form>
			</div>
		</div>

		<div class="col-12">
			<div class="row justify-content-center align-items-center">
				<div class="col">
					<FormMessage v-if="message">{{ message }}</FormMessage>
				</div>
				<div class="col text-right">
					<v-btn
						class="admin-primary-button primary-contrast-background shadow-none"
						@click="createQuiz"
					>Create</v-btn
					>
				</div>
			</div>
		</div>

	</div>
	<!-- <v-container class="main-container">
		<v-row>
			<v-col>
				<backTo elClass="mb-5 d-block" :link="'/admin/quizes'"
					><template #text>Back to quizes</template></backTo
				>
				<admin-default-header>Creating New Quiz</admin-default-header>
				<admin-default-description>
					Lorem, ipsum dolor sit amet consectetur adipisicing elit.
					Ipsam maiores vero quo nemo accusantium quae delectus a illo
					laudantium pariatur, soluta velit repellendus iusto deserunt
					perferendis odit, facere, magni veniam.
				</admin-default-description>
				<v-form ref="form">
					<InputTextField
						:id="'new_quiz_name'"
						v-model="newQuizName"
						:rules="[rules.required]"
					>
						<template #label>Quiz Name</template>
					</InputTextField>
					<InputTextArea
						v-model="newQuizDescription"
						:id="'new_quiz_description'"
					>
						<template #label> Description (Optional)</template>
					</InputTextArea>
				</v-form>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<FormMessage v-if="message">{{ message }}</FormMessage>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex justify-end">
				<v-btn
					class="admin-primary-button primary-contrast-background"
					@click="createQuiz"
					>Create</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>
<script>
import { post } from "@/util/requests/post";
import { mapGetters } from "vuex";

export default {
	name: "NewQuiz",
	computed: {
		...mapGetters(["organization"])
	},
	data() {
		return {
			newQuizName: "",
			newQuizDescription: "",
			message: "",
			rules: {
				required: value => !!value || "Required."
			}
		};
	},
	methods: {
		setValue({ key, value }) {
			this[key] = value;
		},
		setMessage(val) {
			this.message = val;
		},
		validate() {
			// TODO:
			// return (
			// 	!!this.newCourseName && !!this.newCourseType && !!this.template
			// );
		},
		async createQuiz() {
			this.setMessage("");
			// if (!this.validate()) {
			// 	this.setMessage("Select template");
			// 	return;
			// }
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await post("/quiz", {
					name: this.newQuizName,
					organization_id: this.organization.id,
					start_button_text: "Start",
					started_heading: "this is dummy started heading",
					started_html: "<h2>this is dummy started heading</h2>",
					completed_heading: "this is dummy completed heading",
					completed_html: "<h2>this is dummy completed heading</h2>",
					sort: 1,
					custom: 1,
					custom_course_id: null,
					category_id: null,
					type: null
				});
				console.log(response);
				if (response.status === 201) {
					this.$store.dispatch("getQuizzes");
					this.$router.push({ name: "Quizes" });
					this.message = "The quiz has been successfully created";
					this.status = response.status;
				}
			} catch (error) {
				this.message = error.response.data.message;
				this.status = "error";
				this.message = "A problem occurred, try again";
			}
			await this.$store.dispatch("setIsLoading", false);
		}
	}
};
</script>

<style></style>
